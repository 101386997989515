import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {FooterComponent} from './common/footer/footer.component'
import {HeaderComponent} from './common/header/header.component'
import {ErrorDialogComponent} from './common/error-dialog/error-dialog.component'
import {LoginComponent} from './1-login/login.component'
import {MatDialogModule} from '@angular/material/dialog'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {BankIdModule} from '@jhc/bankid'
import {AdminComponent} from './2-admin/admin.component'
import {MatButtonModule} from '@angular/material/button'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {AuthInterceptor} from './application/auth-interceptor'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field'
import {LOCAL_STORAGE_PROVIDERS} from './application/local-storage.provider'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {RegisteredComponent} from './3-registered/registered.component'
import {MatTableModule} from '@angular/material/table'
import {ReactiveFormsModule} from '@angular/forms'
import {DetailsDialogComponent} from './common/details-dialog/details-dialog.component'
import {RegretDialogComponent} from './common/regret-dialog/regret-dialog.component'
import {MatTabsModule} from '@angular/material/tabs'
import {MatInputModule} from '@angular/material/input'
import {MatSortModule} from '@angular/material/sort'
import {MatSelectModule} from '@angular/material/select'
import {UserComponent} from './4-user/user.component'
import {AgreementComponent} from './5-agreement/agreement.component'

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ErrorDialogComponent,
    LoginComponent,
    AdminComponent,
    RegisteredComponent,
    DetailsDialogComponent,
    RegretDialogComponent,
    UserComponent,
    AgreementComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatTableModule,
    MatTabsModule,
    MatInputModule,
    MatSortModule,
    BankIdModule,
    MatSelectModule


  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    },
    LOCAL_STORAGE_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
