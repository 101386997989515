<mat-tab-group #tabGroup>
    <mat-tab label="Välkommen">
        <ng-template matTabContent>
            hej
        </ng-template>
    </mat-tab>
    <mat-tab label="Foto">
        <ng-template matTabContent>
            <foa-registered></foa-registered>
        </ng-template>
    </mat-tab>
    <mat-tab label="Användare">
        <ng-template matTabContent>
            <foa-user></foa-user>
        </ng-template>
    </mat-tab>
    <mat-tab label="Avtal">
        <ng-template matTabContent>
            <foa-agreement></foa-agreement>
        </ng-template>
    </mat-tab>
</mat-tab-group>