import {Component} from '@angular/core'

@Component({
  selector: 'foa-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {

}
