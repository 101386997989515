<div (swiperight)="handleSwipeRight.emit()" class="holder">
    <div class="list-holder">

        <div class="filter-holder">
            <mat-form-field>
                <mat-label>Sök</mat-label>
                <label>
                    <input [formControl]="filterControl" matInput name="filterTrans">
                </label>
            </mat-form-field>
            <div (click)="saveInfo()" class="material-symbols-outlined" style="font-size: 50px">download</div>
        </div>

        <div class="table-holder">
            <table [dataSource]="dataSource" class="mat-elevation-z8" mat-table matSort
                   matSortActive="timeStamp" matSortDirection="desc">
                <caption>Lista över legitimeringar</caption>

                <ng-container matColumnDef="company">
                    <th *matHeaderCellDef id="company" mat-header-cell
                        mat-sort-header="company"> Organisation
                    </th>
                    <td (click)="openDialog(element)" *matCellDef="let element" mat-cell>
                        {{element.company}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="customer">
                    <th *matHeaderCellDef id="customer" mat-header-cell
                        mat-sort-header="customer"> Kund
                    </th>
                    <td (click)="openDialog(element)" *matCellDef="let element" mat-cell>
                        {{element.customer}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="timeStamp">
                    <th *matHeaderCellDef id="timeStamp" mat-header-cell
                        mat-sort-header="timeStamp"> Tidpunkt
                    </th>
                    <td (click)="openDialog(element)" *matCellDef="let element" mat-cell>
                        {{element.timeStamp | date: 'YYYY-MM-dd HH:mm': undefined : 'fr'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th *matHeaderCellDef id="description" mat-header-cell mat-sort-header="description"> Beskrivning
                    </th>
                    <td (click)="openDialog(element)" *matCellDef="let element" mat-cell>
                        {{element.description}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="photo">
                    <th *matHeaderCellDef id="photo" mat-header-cell mat-sort-header="photo"> Foto
                    </th>
                    <td (click)="openDialog(element)" *matCellDef="let element" mat-cell>
                        <img alt="photo" src="{{element.photoUrl}}"
                             style="max-height: 70px; max-width: 70px; padding-top: 6px;">
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
                <tr *matRowDef="let element; columns: columnsToDisplay" mat-row></tr>

                <tr *matNoDataRow class="no-data">
                    <td [attr.colspan]="columnsToDisplay.length" class="no-data">
                        Laddar signeringar...
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div [class.visible]="copyText" class="popup-text">{{copyText}}</div>
</div>
